<template>
  <div>
    <Overlay :overlay="overlay" />
    <v-card class="elevation-0">
      <v-row no-gutters>
        <v-col cols="12" xs="12" sm="12" md="6">
          <v-toolbar class="elevation-0">
            <div class="heading1 PrimaryFontColor">Alarm Logs</div>
          </v-toolbar>
        </v-col>
        <v-col cols="12" xs="12" sm="12" md="6">
          <v-toolbar class="elevation-0">
            <v-spacer></v-spacer>
            <v-menu
              v-model="datePickerMenu"
              :close-on-content-click="false"
              :nudge-right="40"
              transition="scale-transition"
              offset-y
              min-width="290px"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  dense
                  outlined
                  v-model="datePickerVmodel"
                  label="Select Date"
                  prepend-inner-icon="mdi-calendar"
                  class="field_height field_label_size FontSize maxWidth mt-6"
                  style="max-width: 200px"
                  readonly
                  v-bind="attrs"
                  v-on="on"
                ></v-text-field>
              </template>
              <v-date-picker v-model="datePickerVmodel" @input="datePickerMenu = false"></v-date-picker>
            </v-menu>
          </v-toolbar>
        </v-col>
      </v-row>
      <v-card-text class="py-0">
        <v-card outlined class="mt-3">
          <v-data-table dense :headers="alarmsHeaders" :items="GetAllAlarmsWithDateList">
            <template v-slot:[`item.sl_no`]="{ item }">
              <div class="fontSize">{{ GetAllAlarmsWithDateList.indexOf(item) + 1 }}</div>
            </template>
            <template v-slot:[`item.tag_description`]="{ item }">
              <div class="fontSize">{{ item.tag_description }}</div>
            </template>
            <template v-slot:[`item.tag_id`]="{ item }">
              <div class="fontSize">{{ item.tag_id }}</div>
            </template>
            <template v-slot:[`item.date`]="{ item }">
              <div class="fontSize">{{ new Date(item.alarm_time * 1000).toLocaleString("en-In").split(",")[0] }}</div>
            </template>
            <template v-slot:[`item.time`]="{ item }">
              <div class="fontSize">{{ new Date(item.alarm_time * 1000).toLocaleString("en-In").split(",")[1] }}</div>
            </template>
          </v-data-table>
        </v-card>
      </v-card-text>
    </v-card>
  </div>
</template>
<script>
import Overlay from "@/components/Extras/Overlay.vue";
import { GetAllAlarmsWithDate } from "@/mixins/GetAllAlarmsWithDate.js";
export default {
  mixins: [GetAllAlarmsWithDate],
  components: {
    Overlay,
  },
  data: () => ({
    overlay: false,
    datePickerVmodel: new Date().toISOString().substr(0, 10),
    datePickerMenu: false,
    alarmsHeaders: [
      { text: "Sl No", value: "sl_no" },
      { text: "Name", value: "tag_description" },
      { text: "Tag ID", value: "tag_id" },
      { text: "Date", value: "date", sortable: false },
      { text: "Time", value: "time", sortable: false },
    ],
  }),
  watch: {
    datePickerVmodel(val) {
      if (val && val != "") {
        this.GetAllAlarmsWithDateListMethod(this.$store.getters.get_asset_object, this.datePickerVmodel);
      }
    },
  },
  mounted() {
    this.GetAllAlarmsWithDateListMethod(this.$store.getters.get_asset_object, this.datePickerVmodel);
  },
};
</script>
